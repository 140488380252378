<template>
  <div>
    <b-card>
      <b-button
        :variant="activeEnvironment === 'production'?'primary':'flat-primary'"
        class="mr-1"
        @click="onSelectEnvironment('production')"
      >
        <feather-icon
          icon="MonitorIcon"
          class="mr-1"
        />
        Production
      </b-button>
      <b-button
        :variant="activeEnvironment === 'sandbox'?'primary':'flat-primary'"
        @click="onSelectEnvironment('sandbox')"
      >
        <feather-icon
          icon="BoxIcon"
          class="mr-1"
        />
        Sandbox
      </b-button>
    </b-card>

    <b-card>
      <b-row>
        <b-col class="pr-0">
          <b-form-group>
            <v-select
              v-model="selectedGame"
              label="title"
              :options="gameOptions"
            />
          </b-form-group>
        </b-col>
        <b-col class="pr-0">
          <b-form-group>
            <v-select
              v-model="selectedAgent"
              label="title"
              :options="agentOptions"
            />
          </b-form-group>
        </b-col>
        <b-col class="pr-0">
          <b-form-group>
            <v-select
              v-model="selectedType"
              label="title"
              :options="typeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <day-picker />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="search"
              type="search"
              placeholder="Round ID, Username"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="pl-0">
          <b-button
            variant="primary"
            class="mr-1"
            block
            @click="onSearch"
          >
            ค้นหา
          </b-button>
        </b-col>
        <b-col class="pl-0">
          <b-button
            variant="outline-danger"
            block
            @click="search = ''"
          >
            รีเซต
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>{{ `ประวัติการเล่นเกม ${total} รายการ` }}</b-card-title>
        </div>
      </b-card-header>
      <hr class="m-0">

      <template v-if="dataList.length > 0">
        <!-- Table -->
        <b-table
          :items="dataList"
          :fields="fields"
          :busy="isFetching"
          responsive
          show-empty
          hover
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(date)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(bet)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(reward)="data">
            <span :class="data.value > 0?'text-success': 'text-danger'">
              {{ data.value | currency }}
            </span>
          </template>
          <template #cell(fee)="data">
            {{ data.value | currency }}
          </template>
          <template #cell(type)="data">
            <b-badge
              :variant="$displayHistoryColor(data.value)"
            >
              {{ $displayHistoryType(data.value) }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button
              variant="flat-primary"
              size="sm"
              :to="`/game/history/detail/${data.value}`"
            >
              <feather-icon
                icon="FileTextIcon"
                size="20"
              />
            </b-button>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">
              ไม่มีรายชื่อตัวแทน
            </p>
          </template>
        </b-table>
        <!-- pagination -->
        <b-card-footer>
          <b-row class="">
            <b-col md="4">
              <page-limit-select
                :value="limit"
                @update="onLimitChange"
              />
            </b-col>
            <b-col>
              <pagination-input
                :per-page="limit"
                :total="total"
                @update="(val) => (currentPage = val)"
              />
            </b-col>
          </b-row>
        </b-card-footer>
      </template>

      <template v-else>
        <div class="p-4 text-center">
          <p class="text-muted mb-2">
            ไม่พบประวัติการเล่นเกม
          </p>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'GameHistory',
  components: {
    vSelect,
  },
  data() {
    return {
      activeEnvironment: 'production',

      selectedGame: { title: 'ทุกเกม', value: 'all' },
      gameOptions: [{ title: 'ทุกเกม', value: 'all' }],

      selectedAgent: { title: 'ทุกตัวแทน', value: 'all' },
      agentOptions: [{ title: 'ทุกตัวแทน', value: 'all' }],

      selectedType: { title: 'ทุกประเภท', value: 'all' },
      typeOptions: [{ title: 'ทุกประเภท', value: 'all' }],

      search: '',
      onSearchTimeout: null,
      currentPage: 1,
      selectedLimit: 20,

      fields: [
        {
          key: 'roundId',
          label: 'Round ID',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'gameName',
          label: 'ชื่อเกม',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'userName',
          label: 'Username',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'bet',
          label: 'จำนวนที่เดิมพัน',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'reward',
          label: 'เงินรางวัล (หัก % แล้ว)',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'fee',
          label: 'ค่าธรรมเนียม',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'date',
          label: 'วันที่',
          thStyle: {
            minWidth: '220px',
          },
        },
        {
          key: 'type',
          label: 'ประเภท',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'agentName',
          label: 'ตัวแทน',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.game.isGettingGameHistory,
    }),
    ...mapGetters(['gameHistory']),
    dataList() {
      return (
        this.gameHistory ||
        [
          {
            id: '1',
            roundId: 'roundId',
            gameName: 'gameName',
            betAmount: 100,
            prize: 90,
            fee: 10,
            date: new Date(),
            username: 'username',
            type: 'win',
            agent: 'agent',
          },
          {
            id: '2',
            roundId: 'roundId',
            gameName: 'gameName',
            betAmount: 100,
            prize: 0,
            fee: 0,
            date: new Date(),
            username: 'username',
            type: 'loss',
            agent: 'agent',
          },
          {
            id: '3',
            roundId: 'roundId',
            gameName: 'gameName',
            betAmount: 100,
            prize: 0,
            fee: 0,
            date: new Date(),
            username: 'username',
            type: 'bot',
            agent: 'agent',
          },
        ]
      )
    },
    pagination() {
      return {
        itemsPerPage: 0,
        totalItems: 0,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getGameHistory']),
    fetchData() {
      this.getGameHistory()
    },
    onSelectEnvironment(env) {
      if (env !== this.activeEnvironment) {
        this.activeEnvironment = env
        this.fetchData()
      }
    },
    onSearch() {},
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onRowSelected(val) {
      this.$router.push(`/game/history/detail/${val[0].id}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>